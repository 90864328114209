// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'jquery'
import 'bootstrap'

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  var container = $('body');

  $('.js-tooltip').tooltip();

  $('.js-copy').click(function() {
    var text = $(this).attr('data-copy');
    var el = $(this);
    BannerPackUpload.copy_to_clipboard(text, el);
  });


  window.BannerPackUpload = {
    status_icon: function(type)
    {
      if(type == 'approved')
      {
        var klasses = "fas fa-check fa-1x banner_status text-success"
      }
      else if(type == 'commented')
      {
        var klasses = "fas fa-circle fa-1x banner_status text-warning"
      }

      return '<i class="' + klasses + '"></i>'
    },
    copy_to_clipboard: function(text, el) {
      var copyTest = document.queryCommandSupported('copy');
      var elOriginalText = el.attr('data-bs-original-title');

      if (copyTest === true) {
        var copyTextArea = document.createElement("textarea");
        copyTextArea.value = text;
        document.body.appendChild(copyTextArea);
        copyTextArea.select();
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? el.attr('data-bs-success') : el.attr('data-bs-failure');
          el.attr('data-bs-original-title', msg).tooltip('show');
        } catch (err) {
          console.log('Oops, unable to copy');
        }
        document.body.removeChild(copyTextArea);
        el.attr('data-bs-original-title', elOriginalText);
      } else {
        // Fallback if browser doesn't support .execCommand('copy')
        window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", text);
      }
    }
  }
});

require("trix")
require("@rails/actiontext")
